import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components'
import Title from "../../../../../components/title";
import Input from "../../../../../components/input";
import Select from "../../../../../components/select";
import { connect, useSelector } from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faExclamationTriangle, faPlus, faTimesCircle, faTrash} from "@fortawesome/free-solid-svg-icons";
import Button from "../../../../../components/button";
import PDFGenerator from "./pdfGenerator";
import {
    getAllLeasingPrice, getAllLoanPrice,
    getRate,
    getRateLoan,
    tabelaProwizji,
    tabelaProwizjiLeasingu,
    tabelaProwizjiPozyczki
} from "../../../../../theme/calculator";
import CheckBox from "../../../../../components/checkbox";
import {monthsOptions} from "../../../../../theme/theme";
import SaveNew from "./saveNew";
import {db} from "../../../../../firebase/firebase";
import Spinner from "../../../../../components/spinner";
import AllTemplates from "./allTemplates";

const DOUBLE_REGEX = /^[0-9]*(.|.([0-9]|[0-9][0-9]))?$/;

function OfferGenerator ({user,workers}) {
    const wibor = useSelector(store => store.configuration.wibor)
    const margin = useSelector(store => store.configuration.margin)

    const [allTemplates,setAllTemplates] = useState([]);
    const [updateLoading,setUpdateLoading] = useState(false);
    const [addNew,setAddNew] = useState(false);
    const [allTemplateView,setAllTemplateView] = useState(false);
    const [state,setState] = useState({
        id:null,
        client:'',
        typeName:'Samochód',
        type:'Pojazdy do 3,5 t',
        state:'Nowy',
        brand:'',
        model:'',
        version:'',
        bodyType:'',
        power:'',
        year:'2025',
        catalogPrice:'',
        price:'',
        financeType:'Leasing',
        otherFinanceOption:'Leasing',
        leasingTable:[],
        rentTable:[],
        additionalOffers:true,
        user:workers.find(item => item.name == user.name),
    });
    const [leasing,setLeasing] = useState({
        type:'Standard',
        marza:margin.leasing['2024'],
        prowizja:'4',
        period:48,
        contribution:{
            type:'%',
            value:'10'
        },
        repurchase:{
            type:'%',
            value:'10'
        },
        rate:'',
        allCosts:false,
        otherOptions:[]
    });

    const [loan,setLoan] = useState({
        insurance:'Leasing',
        marza:margin.pozyczka['2024'],
        prowizja:'1',
        period:48,
        contribution:{
            type:'%',
            value:'10'
        },
        rate:'',
        otherOptions:[]
    });

    const [rent,setRent] = useState({
        period:48,
        contribution:{
            type:'%',
            value:''
        },
        rate:'',
        limit:'15000',
        serwis:true,
        wheels:true,
        carInsurance:true,
        replaceCar:true,
        carInsuranceValue:'',
        otherOptions:[]
    });

    const onSuccessSave = (id,name,template) => {
        if(allTemplates.find(item => item.id === id)){
            const index =  allTemplates.findIndex(item => item.id === id);
            const array = allTemplates;
            array[index].object = template;
            setAllTemplates(array);
        }else {
            setAllTemplates([...allTemplates,{
                id:id,
                name:name,
                object:template
            }])
        }
        setState({
            ...template.state,
            id
        });
        setLeasing(template.leasing);
        setRent(template.rent);
        setLoan(template.loan);
    };

    const deleteTemplate = (id) => {
        if(window.confirm('Czy chcesz usunąc szablon ?')){
            db.collection('users').doc(user.uid).collection('templates').doc(id).delete().then(function() {
                setAllTemplates(allTemplates.filter(item => item.id !== id))
            }).catch(function(error) {
                console.error("Error removing document: ", error);
            });
        }
    };

    const chooseTemplate = (id) => {
        const template = allTemplates.find(item => item.id === id);
        if(template){
            setState({
                catalogPrice:'',
                typeName:'Samochód',
                ...template.object.state
            });
            setLeasing(template.object.leasing);
            setLoan({
                ...template.object.loan,
                insurance: template.object.loan.insurance === "Alior" ? "Leasing" : template.object.loan.insurance
            });
            setRent(template.object.rent);
            setAllTemplateView(false);
        }
    };

    const onUpdateTemplate = () => {
        setUpdateLoading(true);
        const object = {
            state,
            leasing,
            loan,
            rent
        };

        db.collection('users').doc(user.uid).collection('templates').doc(state.id).update({
            object: JSON.stringify(object)
        })
            .then(response => {
                setUpdateLoading(false);
                const array = allTemplates;
                const index = array.findIndex(item => item.id === state.id);
                array[index].object = object;

                setAllTemplates(array);
            })
            .catch(err => {
                console.log(err);
                setUpdateLoading(false);
            })
    };

    useEffect(() => {
        const array = [];
        db.collection('users').doc(user.uid).collection('templates')
            .get()
            .then(function(querySnapshot) {
                querySnapshot.forEach(function(doc) {
                    const item =  doc.data();
                    array.push({
                        id:item.id,
                        name:item.name,
                        object:JSON.parse(item.object)
                    })
                });
                setAllTemplates(array);
            })
            .catch(function(error) {
                console.log("Error getting documents: ", error);
            });
    },[]);

    const getLeasingRate = (object) => {
        return getRate(state.price,object.period,object.contribution.value,object.contribution.type,object.repurchase.value,object.repurchase.type,leasing.marza,leasing.prowizja,user.commission ? parseFloat(user.commission) : 0, wibor);
    };

    const getLoanRate = (object) => {
        return getRateLoan(state.price,object.period,object.contribution.type,object.contribution.value,loan.marza,loan.prowizja,user.commission ? parseFloat(user.commission) : 0);
    };

    useEffect(() => {
        const rate = getLeasingRate(leasing);
        setLeasing({
            ...leasing,
            rate
        });

        updateEveryRow(leasing,getLeasingRate);
    },[state.price,leasing.type,leasing.marza,leasing.prowizja,leasing.period,leasing.contribution.value,leasing.contribution.type,,leasing.repurchase.value,leasing.repurchase.type,leasing.otherOptions]);

    useEffect(() => {
        const rate = getLoanRate(loan);
        setLoan({
            ...loan,
            rate
        });
        updateEveryRow(loan,getLoanRate);
    },[state.price,loan.marza,loan.prowizja,loan.period,loan.contribution.type,loan.contribution.value,loan.insurance]);


    const updateEveryRow = (object,getRate) => {
        const array = object.otherOptions;

        object.otherOptions.map((item,index) => {
            array[index].rate = getRate(item);
        })
    };

    const onRowChange = (object,setObject,index,e,updateRate,calculateRate,reg) => {
        const array = object.otherOptions;

        if(reg==='double'){
            if (/^[0-9]*(.|.([0-9]|[0-9][0-9]))?$/.test(e.target.value)) {
                array[index][e.target.name] = e.target.value;
            }
        }else if(reg==='int'){
            if (/^[0-9]*$/.test(e.target.value)) {
                array[index][e.target.name] = e.target.value;
            }
        }else {
            array[index][e.target.name] = e.target.value;
        }
        if(updateRate){
            array[index].rate = calculateRate(array[index]);
        }
        setObject({
            ...object,
            otherOptions:array
        })
    };

    const onSubRowChange = (name,object,setObject,index,e,updateRate,calculateRate,reg) => {
        const array = object.otherOptions;

        if(reg==='double'){
            if (/^[0-9]*(.|.([0-9]|[0-9][0-9]))?$/.test(e.target.value)) {
                array[index][e.target.name][name] = e.target.value;
            }
        }else if(reg==='int'){
            if (/^[0-9]*$/.test(e.target.value)) {
                array[index][e.target.name][name] = e.target.value;
            }
        }else {
            array[index][e.target.name][name] = e.target.value;
        }
        if(updateRate){
            array[index].rate = calculateRate(array[index]);
        }
        setObject({
            ...object,
            otherOptions:array
        })
    };

    const updateYear = (e) => {
        setState({
            ...state,
            year: e.target.value,
        });

        setLeasing({
            ...leasing,
            marza:margin.leasing[e.target.value]
        });

        setLoan({
            ...loan,
            marza:margin.pozyczka[e.target.value]
        })
    };

    const updateState = (e) => {
        setState({
            ...state,
            state: e.target.value,
        });

        setLeasing({
            ...leasing,
            marza:margin.leasing[state.year]
        });

        setLoan({
            ...loan,
            marza:margin.pozyczka[state.year]
        })
    };

    const updateLoanType = (e) => {
        setLoan({
            ...loan,
            insurance: e.target.value,
            marza: margin.pozyczka[state.year]
        });
    };

    const updateLeasingType = (e) => {
        setLeasing({
            ...leasing,
            type: e.target.value,
            marza: margin.leasing[state.year]
        });
    };

    const handleIntInputChange = (e,object,setObject) => {
        if (/^[0-9]*$/.test(e.target.value)) {
            setObject({...object,[e.target.name]:e.target.value});
        }
    };

    const handleDoubleInputChange = (e,object,setObject) => {
        if (/^[0-9]*(.|.([0-9]|[0-9][0-9]))?$/.test(e.target.value)) {
            setObject({...object,[e.target.name]:e.target.value});
        }
    };


    return(
        <>
        <Wrapper>
            <ContentHeader>
                <Title>Generator ofert</Title>
                <Flex>
                    <Button secondary background="white" margin="0 10px 0 0" onClick={() => setAllTemplateView(true)}>
                        Otwórz
                    </Button>
                    {
                        state.id ?
                            <Button
                                onClick={onUpdateTemplate}
                                subMenu={[['Zapisz jako nowy',() => setAddNew(true)]]}
                            >
                                {
                                    updateLoading ?
                                        <Spinner borderWidth={3} white width={10} height={10}/> :
                                        <>Aktualizuj</>
                                }
                            </Button> :
                            <Button onClick={() => setAddNew(true)}>
                                Zapisz nowy
                            </Button>
                    }

                </Flex>
            </ContentHeader>
            <ContentBody>
                <Block>
                    <Title third>Klient</Title>
                    <Grid gtc="1fr">
                        <Input label="Nazwa klienta" name="client" value={state.client} onChange={(e) => setState({...state,client:e.target.value})}/>
                    </Grid>
                </Block>
                <Block>
                    <Title third>Dane samochodu</Title>
                    <Grid gtc="1fr 1fr 1fr 1fr 1fr">
                        <Select label="Przedmiot" name="typeName" value={state.typeName} options={['Samochód','Motocykl','Maszyna','Ciagnik siodłowy','Naczepa']} onChange={(e) => setState({...state,typeName:e.target.value})}/>
                        <Select label="Typ" name="type" value={state.type} options={['Pojazdy do 3,5 t','Pojazdy pow. 3,5 t']} onChange={(e) => setState({...state,type:e.target.value})}/>
                        <Input label="Marka" name="brand" value={state.brand} onChange={(e) => setState({...state,brand:e.target.value})}/>
                        <Input label="Model" name="model" value={state.model} onChange={(e) => setState({...state,model:e.target.value})}/>
                        <Input label="Wersja" name="version" value={state.version} onChange={(e) => setState({...state,version:e.target.value})}/>
                    </Grid>
                    <Grid gtc="1fr 1fr 1fr 1fr">
                        {state.typeName === 'Samochód' && <Input label="Nadwozie" name="bodyType" value={state.bodyType} onChange={(e) => setState({...state,bodyType:e.target.value})}/>}
                        {(state.typeName === 'Samochód' || state.typeName === 'Motocykl') && <Input label="Moc" endAdornment="KM" name="power" value={state.power} onChange={(e) => setState({...state,power:e.target.value})}/>}
                        <Select label="Stan" options={['Nowy','Używany']} name="year" value={state.state} onChange={updateState}/>
                        <Select label="Rok produkcji" options={[2025, 2024,2023,2022,2021,2020,2019,2018,2017,2016,2015,2014,2013,2012,2011,2010,2009,2008,2007,2006,2005,2004]} name="year" value={state.year} onChange={updateYear}/>
                    </Grid>
                </Block>
                <Block>
                    <Title third>Dane finansowania</Title>
                    <Flex>
                        <Input label="Cena bazowa" endAdornment="PLN" name="catalogPrice" value={state.catalogPrice} onChange={(e) => {
                            if (/^[0-9]*(.|.([0-9]|[0-9][0-9]))?$/.test(e.target.value)) {
                                setState({...state,catalogPrice:e.target.value,price:e.target.value});
                            }
                        }}/>
                        <Input label="Cena finansowania" endAdornment="PLN" name="price" value={state.price} onChange={(e) => handleDoubleInputChange(e,state,setState)}/>
                    </Flex>
                    <div style={{marginTop:'10px',display:'grid',gridTemplateColumns:'1fr 1fr'}}>
                        <div>
                            <Title fourth>Leasing</Title>
                            <div>
                                {
                                    parseFloat(leasing.prowizja.replaceAll(",",".")) > 4 &&
                                    <WarrningInfo>
                                        <FontAwesomeIcon icon={faExclamationTriangle}/>Marża banku została zwiększona o {Math.round(((parseFloat(leasing.prowizja.replaceAll(",","."))-4)/2)*100) / 100}%</WarrningInfo>
                                }
                            </div>
                            <Flex>
                                {/*<Select label="Rodzaj leasingu" options={['Standard','Standard+']} name="type" value={leasing.type} onChange={updateLeasingType}/>*/}
                                <Input maxWidth="100px" label="Marża" endAdornment="%" name="marza" value={leasing.marza} onChange={(e) => handleDoubleInputChange(e,leasing,setLeasing)}/>
                                <Input maxWidth="100px" label="Prowizja" endAdornment="%" name="prowizja" value={leasing.prowizja} onChange={(e) => handleDoubleInputChange(e,leasing,setLeasing)}/>
                            </Flex>
                        </div>
                        <div>
                            <Title fourth>Pożyczka</Title>
                            <Flex>
                                {/*<Select label="Ubezpieczenie" maxWidth="200px" options={['Leasing','Obce']} name="insurance" value={loan.insurance} onChange={updateLoanType}/>*/}
                                <Input maxWidth="100px" label="Marża" endAdornment="%" name="marza" value={loan.marza} onChange={(e) => handleDoubleInputChange(e,loan,setLoan)}/>
                                <Input maxWidth="100px" label="Prowizja" endAdornment="%" name="prowizja" value={loan.prowizja} onChange={(e) => handleDoubleInputChange(e,loan,setLoan)}/>
                            </Flex>
                        </div>
                    </div>

                </Block>
                <Block>
                    <Title third>Podstawowe finansowanie</Title>
                    <Grid gtc={state.financeType === 'Leasing' ? '2fr 1fr 2fr 1fr 2fr 1fr 2fr max-content' : state.financeType === 'Wynajem' ? '2fr 2fr 2fr 1fr 2fr' : '2fr 2fr 2fr 1fr 2fr max-content'}>
                        <Select label="Finansowanie" options={['Leasing','Pożyczka','Wynajem']} name="financeType" value={state.financeType} onChange={(e) => setState({...state,financeType:e.target.value})}/>
                        {
                            state.financeType === 'Leasing' &&
                                <>
                                    <Select label="Okres" endAdornment="mies." options={monthsOptions} name="period" value={leasing.period} onChange={(e) => setLeasing({...leasing,period:e.target.value})}/>
                                    <Input label="Wpłata" endAdornment={leasing.contribution.type} name="contribution" value={leasing.contribution.value}
                                           onChange={(e) =>{
                                               if (DOUBLE_REGEX.test(e.target.value)) {
                                                   setLeasing({...leasing,contribution:{
                                                           type:leasing.contribution.type,
                                                           value:e.target.value,
                                                       }});
                                               }
                                           }}
                                    />
                                    <Select maxWidth="none" label="PLN/%" options={['PLN','%']} name="type" value={leasing.contribution.type}
                                            onChange={(e) =>{
                                                setLeasing({...leasing,contribution:{
                                                        type:e.target.value,
                                                        value:leasing.contribution.value,
                                                    }});
                                            }}
                                    />
                                    <Input label="Wykup" endAdornment={leasing.repurchase.type} name="repurchase" value={leasing.repurchase.value}
                                           onChange={(e) =>{
                                               if (DOUBLE_REGEX.test(e.target.value)) {
                                                   setLeasing({...leasing,repurchase:{
                                                           type:leasing.repurchase.type,
                                                           value:e.target.value,
                                                       }});
                                               }
                                           }}
                                    />
                                    <Select maxWidth="none" label="PLN/%" options={['PLN','%']} name="type" value={leasing.repurchase.type}
                                            onChange={(e) =>{
                                                    setLeasing({...leasing,repurchase:{
                                                            type:e.target.value,
                                                            value:leasing.repurchase.value,
                                                        }});
                                            }}
                                    />
                                    <Input label="Rata" endAdornment="PLN" name="rate" value={leasing.rate} onChange={(e) => setLeasing({...leasing,rate:e.target.value})}/>
                                    <GetRate onClick={() => {
                                        const rate = getRate(state.price,leasing.period,leasing.contribution.value,leasing.contribution.type,leasing.repurchase.value,leasing.repurchase.type,leasing.marza,leasing.prowizja,user.options && user.options.includes('partner') ? 2 : 0, wibor);
                                        setLeasing({
                                            ...leasing,
                                            rate:rate
                                        })
                                    }}>
                                        <FontAwesomeIcon icon={faDownload}/>
                                    </GetRate>
                                    <div>
                                        <CheckBox label={`Pokaż koszt całkowity ${getAllLeasingPrice(state,leasing)[0]} PLN (${getAllLeasingPrice(state,leasing)[1]}%)`} value={leasing.allCosts} onChange={() => setLeasing({...leasing,allCosts:!leasing.allCosts})}/>
                                    </div>
                                </>
                        }
                        {
                            state.financeType === 'Pożyczka' &&
                            <>
                                <Select label="Okres" endAdornment="mies." options={monthsOptions} name="period" value={loan.period} onChange={(e) => setLoan({...loan,period:e.target.value})}/>
                                <Input label="Wpłata" endAdornment={loan.contribution.type} name="contribution" value={loan.contribution.value}
                                       onChange={(e) =>{
                                           if (DOUBLE_REGEX.test(e.target.value)) {
                                               setLoan({...loan,contribution:{
                                                       type:loan.contribution.type,
                                                       value:e.target.value,
                                                   }});
                                           }
                                       }}
                                />
                                <Select maxWidth="none" label="PLN/%" options={['PLN','%']} name="type" value={loan.contribution.type}
                                        onChange={(e) =>{
                                            setLoan({...loan,contribution:{
                                                    type:e.target.value,
                                                    value:loan.contribution.value,
                                                }});
                                        }}
                                />
                                <Input label="Rata" endAdornment="PLN" name="rate" value={loan.rate} onChange={(e) => setLoan({...loan,rate:e.target.value})}/>
                                <GetRate onClick={() => {
                                    const rate = getLoanRate(loan);
                                    setLoan({
                                        ...loan,
                                        rate:rate
                                    })
                                }}>
                                    <FontAwesomeIcon icon={faDownload}/>
                                </GetRate>
                                <div>
                                    <CheckBox label={`Pokaż koszt całkowity ${getAllLoanPrice(state,loan)[0]} PLN (${getAllLoanPrice(state,loan)[1]}%)`} value={loan.allCosts} onChange={() => setLoan({...loan,allCosts:!loan.allCosts})}/>
                                </div>
                            </>
                        }
                        {
                            state.financeType === 'Wynajem' &&
                            <>
                                <Select label="Okres" endAdornment="mies." options={monthsOptions} name="period" value={rent.period} onChange={(e) => setRent({...rent,period:e.target.value})}/>
                                <Input label="Wpłata" endAdornment={rent.contribution.type} name="contribution" value={rent.contribution.value}
                                       onChange={(e) =>{
                                           if (DOUBLE_REGEX.test(e.target.value)) {
                                               setRent({...rent,contribution:{
                                                       type:rent.contribution.type,
                                                       value:e.target.value,
                                                   }});
                                           }
                                       }}
                                />
                                <Select maxWidth="none" label="PLN/%" options={['PLN','%']} name="type" value={rent.contribution.type}
                                        onChange={(e) =>{
                                            setRent({...rent,contribution:{
                                                    type:e.target.value,
                                                    value:rent.contribution.value,
                                                }});
                                        }}
                                />
                                <Input label="Rata" endAdornment="PLN" name="rate" value={rent.rate} onChange={(e) => setRent({...rent,rate:e.target.value})}/>
                                <div style={{gridColumnStart:1,gridColumnEnd:6}}>
                                    <Flex>
                                        <Input label="Limit KM" endAdornment="KM" name="limit" value={rent.limit}
                                               onChange={(e) =>{
                                                   if (DOUBLE_REGEX.test(e.target.value)) {
                                                       setRent({...rent,limit:e.target.value,});
                                                   }
                                               }}
                                        />
                                        <CheckBox label="Serwis" value={rent.serwis} onChange={(e) => setRent({...rent,serwis:e.target.checked})}/>
                                        <CheckBox label="Opony" value={rent.wheels} onChange={(e) => setRent({...rent,wheels:e.target.checked})}/>
                                        <CheckBox label="Ubezpieczenie" value={rent.carInsurance} onChange={(e) => setRent({...rent,carInsurance:e.target.checked})}/>
                                        <CheckBox label="Auto zastępcze" value={rent.replaceCar} onChange={(e) => setRent({...rent,replaceCar:e.target.checked})}/>
                                        {
                                            rent.carInsurance &&
                                            <Input label="Cena ubezpieczenia" endAdornment="PLN" value={rent.carInsuranceValue} onChange={(e) => setRent({...rent,carInsuranceValue:e.target.value})}/>
                                        }
                                    </Flex>
                                </div>
                            </>
                        }
                    </Grid>
                </Block>
                <Block>
                    <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                        <Title third>Dodatkowe finansowanie</Title>
                        <Select label="" options={['Leasing','Pożyczka','Wynajem']} name="otherFinanceOption" value={state.otherFinanceOption} onChange={(e) => setState({...state,otherFinanceOption:e.target.value})}/>

                        {/*<div>*/}
                        {/*    <CheckBox label="Leasing" name="leasing" value={state.otherFinanceOption.leasing} onChange={onChangeCheckboxFinance}/>*/}
                        {/*    <CheckBox label="Pożyczka" name="loan"  value={state.otherFinanceOption.loan} onChange={onChangeCheckboxFinance}/>*/}
                        {/*    <CheckBox label="Wynajem" name="rent"  value={state.otherFinanceOption.rent} onChange={onChangeCheckboxFinance}/>*/}
                        {/*</div>*/}
                    </div>
                    <AditionalFinanceTypes>
                        {
                            // state.otherFinanceOption.leasing &&
                            state.otherFinanceOption === 'Leasing' &&
                            <div>
                                <Title fourth>Leasing</Title>
                                {
                                    leasing.otherOptions.map((item,index) =>
                                        <Grid gtc="max-content 2fr 2fr 1fr 2fr 1fr 2fr max-content">
                                            <div style={{display:'flex',alignItems:'center',justifyContent:'center',opacity:0.6}} onClick={() => setLeasing({...leasing,otherOptions:leasing.otherOptions.slice(0,index).concat(leasing.otherOptions.slice(index+1))})}>
                                                <FontAwesomeIcon icon={faTimesCircle}/>
                                            </div>
                                            <Select label="Okres" endAdornment="mies." options={monthsOptions} name="period" value={item.period} onChange={(e) => onRowChange(leasing,setLeasing,index,e,true,getLeasingRate)}/>
                                            <Input label="Wpłata" endAdornment={item.contribution.type} name="contribution" value={item.contribution.value} onChange={(e) => onSubRowChange('value',leasing,setLeasing,index,e,true,getLeasingRate,'double')}/>
                                            <Select label="PLN/%" maxWidth="none" options={['PLN','%']} name="contribution" value={item.contribution.type} onChange={(e) => onSubRowChange('type',leasing,setLeasing,index,e,true,getLeasingRate)}/>
                                            <Input label="Wykup" endAdornment={item.repurchase.type} name="repurchase" value={item.repurchase.value} onChange={(e) => onSubRowChange('value',leasing,setLeasing,index,e,true,getLeasingRate,'double')}/>
                                            <Select label="PLN/%" maxWidth="none" options={['PLN','%']} name="repurchase" value={item.repurchase.type} onChange={(e) => onSubRowChange('type',leasing,setLeasing,index,e,true,getLeasingRate)}/>
                                            <Input label="Rata" endAdornment="PLN" name="rate" value={item.rate} onChange={(e) => onRowChange(leasing,setLeasing,index,e,false)}/>
                                            <GetRate onClick={() => {
                                                const rate = getLeasingRate(item);
                                                onRowChange(leasing,setLeasing,index,{
                                                    target:{
                                                        name:'rate',
                                                        value:rate
                                                    }
                                                });
                                            }}>
                                                <FontAwesomeIcon icon={faDownload}/>
                                            </GetRate>
                                        </Grid>
                                    )
                                }
                                {
                                    leasing.otherOptions.length < 5 &&
                                    <AddButton onClick={() => {
                                        const leasingArray = leasing.otherOptions;
                                        leasingArray.push({
                                            period:'48',
                                            contribution:{
                                                type:'%',
                                                value:'10'
                                            },
                                            repurchase:{
                                                type:'%',
                                                value:'10'
                                            },
                                            rate:'',
                                        });

                                        setLeasing({
                                            ...leasing,
                                            otherOptions:leasingArray
                                        })
                                    }}>
                                        <FontAwesomeIcon icon={faPlus}/> Dodaj opcje
                                    </AddButton>
                                }
                            </div>
                        }
                        {
                            // state.otherFinanceOption.loan &&
                            state.otherFinanceOption === 'Pożyczka' &&
                            <div>
                                <Title fourth>Pożyczka</Title>
                                {
                                    loan.otherOptions.map((item,index) =>
                                        <Grid gtc="max-content 2fr 2fr 1fr 2fr max-content">
                                            <div style={{display:'flex',alignItems:'center',justifyContent:'center',opacity:0.6}} onClick={() => setLoan({...loan,otherOptions:loan.otherOptions.slice(0,index).concat(loan.otherOptions.slice(index+1))})}>
                                                <FontAwesomeIcon icon={faTimesCircle}/>
                                            </div>
                                            <Select label="Okres" endAdornment="mies." options={monthsOptions} name="period" value={item.period} onChange={(e) => onRowChange(loan,setLoan,index,e,true,getLoanRate)}/>
                                            <Input label="Wpłata" endAdornment={item.contribution.type} name="contribution" value={item.contribution.value} onChange={(e) => onSubRowChange('value',loan,setLoan,index,e,true,getLoanRate,'double')}/>
                                            <Select label="PLN/%" maxWidth="none" options={['PLN','%']} name="contribution" value={item.contribution.type} onChange={(e) => onSubRowChange('type',loan,setLoan,index,e,true,getLoanRate)}/>
                                            <Input label="Rata" endAdornment="PLN" name="rate" value={item.rate} onChange={(e) => onRowChange(loan,setLoan,index,e,false)}/>
                                            <GetRate onClick={() => {
                                                const rate = getLoanRate(item);
                                                onRowChange(loan,setLoan,index,{
                                                    target:{
                                                        name:'rate',
                                                        value:rate
                                                    }
                                                });
                                            }}>
                                                <FontAwesomeIcon icon={faDownload}/>
                                            </GetRate>
                                        </Grid>
                                    )
                                }
                                {
                                    loan.otherOptions.length < 5 &&
                                    <AddButton onClick={() => {
                                        const array = loan.otherOptions;
                                        array.push({
                                            period: '48',
                                            priceType: '%',
                                            contribution:{
                                                type:'%',
                                                value:'10'
                                            },
                                            rate: '',
                                        });

                                        setLoan({
                                            ...loan,
                                            otherOptions: array
                                        })
                                    }}>
                                        <FontAwesomeIcon icon={faPlus}/> Dodaj opcje
                                    </AddButton>
                                }
                            </div>
                        }
                        {
                            // state.otherFinanceOption.rent &&
                            state.otherFinanceOption === 'Wynajem' &&
                            <div>
                                <Title fourth>Wynajem</Title>
                                {
                                    rent.otherOptions.map((item,index) =>
                                        <Grid gtc="max-content 2fr 2fr 2fr 1fr 2fr 2fr">
                                            <div style={{display:'flex',alignItems:'center',justifyContent:'center',opacity:0.6}} onClick={() => setRent({...rent,otherOptions:rent.otherOptions.slice(0,index).concat(rent.otherOptions.slice(index+1))})}>
                                                <FontAwesomeIcon icon={faTimesCircle}/>
                                            </div>
                                            <Select label="Okres" endAdornment="mies." options={monthsOptions} name="period" value={item.period} onChange={(e) => onRowChange(rent,setRent,index,e,false)}/>
                                            <Input label="Limit KM" endAdornment="KM" name="limit" value={item.limit} onChange={(e) => onRowChange(rent,setRent,index,e,false,() => {}, 'double')}/>
                                            <Input label="Wpłata" endAdornment={item.contribution.type} name="contribution" value={item.contribution.value} onChange={(e) => onSubRowChange('value',rent,setRent,index,e,false,() => {}, 'double')}/>
                                            <Select label="PLN/%" maxWidth="none" options={['PLN','%']} name="contribution" value={item.contribution.type} onChange={(e) => onSubRowChange('type',rent,setRent,index,e,false)}/>
                                            <Input label="Rata" endAdornment="PLN" name="rate" value={item.rate} onChange={(e) => onRowChange(rent,setRent,index,e,false)}/>
                                            <Flex>
                                                <CheckBox label="S" value={item.serwis}  onChange={(e) => onRowChange(rent,setRent,index,{target:{name:'serwis',value:e.target.checked}},false)}/>
                                                <CheckBox label="O" value={item.wheels}  onChange={(e) => onRowChange(rent,setRent,index,{target:{name:'wheels',value:e.target.checked}},false)}/>
                                                <CheckBox label="U" value={item.carInsurance}  onChange={(e) => onRowChange(rent,setRent,index,{target:{name:'carInsurance',value:e.target.checked}},false)}/>
                                                <CheckBox label="AZ" value={item.replaceCar}  onChange={(e) => onRowChange(rent,setRent,index,{target:{name:'replaceCar',value:e.target.checked}},false)}/>
                                            </Flex>
                                        </Grid>
                                    )
                                }
                                {
                                    rent.otherOptions.length < 5 &&
                                    <AddButton onClick={() => {
                                        const array = rent.otherOptions;
                                        array.push({
                                            period: '48',
                                            contribution:{
                                                type:'%',
                                                value:'10'
                                            },
                                            rate: '',
                                            limit: '',
                                            serwis:true,
                                            wheels:true,
                                            carInsurance:true,
                                            replaceCar:true,
                                        });

                                        setRent({
                                            ...rent,
                                            otherOptions: array
                                        })
                                    }}>
                                        <FontAwesomeIcon icon={faPlus}/> Dodaj opcje
                                    </AddButton>
                                }
                            </div>
                        }
                    </AditionalFinanceTypes>
                </Block>
                <Block>
                    <Title third>Dodatkowe oferty</Title>
                    <Grid>
                        <CheckBox label="Czy chcesz pokazywać dodatkowe oferty ?" value={state.additionalOffers}  onChange={(e) => setState({...state,additionalOffers:e.target.checked})}/>
                    </Grid>
                </Block>
                {
                    !(user.options && user.options.includes('partner')) &&
                    <Block>
                        <Title third>Opiekun</Title>
                        <Grid>
                            <Select label="Opiekun" options={workers.map(item => item.name)} name="user"
                                    value={state.user.name} onChange={(e) => setState({
                                ...state,
                                user: workers.filter(item => !(item.options && item.options.includes('partner'))).find(item => item.name === e.target.value)
                            })}/>
                        </Grid>
                    </Block>
                }
                <PDFGenerator state={state} leasing={leasing} loan={loan} rent={rent} leasingAllPrice={() => getAllLeasingPrice(state,leasing)} loanAllPrice={() => getAllLoanPrice(state,loan)}/>
            </ContentBody>
        </Wrapper>
            {
                allTemplateView &&
                    <AllTemplates
                        chooseTemplate={chooseTemplate}
                        deleteTemplate={deleteTemplate}
                        templates={allTemplates}
                        onBack={() => setAllTemplateView(false)}
                        />
            }
            {
                addNew &&
                    <SaveNew
                        state={state}
                        leasing={leasing}
                        rent={rent}
                        loan={loan}
                        onSuccess={onSuccessSave}
                        onBack={() => setAddNew(false)}/>
            }
        </>
    )
};

OfferGenerator.propTypes = {
};

function mapStateToProps(state) {
    return {
        user:state.auth.user,
        workers:state.workers
    };
}
export default connect(mapStateToProps)(OfferGenerator);


const WarrningInfo  = styled.p`
  font-size: ${({theme}) => theme.font.XS};
  opacity: 1;
  font-weight: 600;
    margin: 0px;
  margin-bottom: 15px;
  padding: 5px 15px;
  background: rgba(255, 174, 66,0.2);
  color: #f58c00;
  border-radius: 3px;
  width: max-content;
  svg{
    margin-right: 10px;
  }
`;

const GetRate  = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: none;
  opacity: 1;
  transition: all 0.3s;
  color: ${({theme}) => theme.thirdColor};
  cursor: pointer;
  
  &:hover{
    color: ${({theme}) => theme.primaryColor};
  }
`;

const AddButton = styled.button`
  border: none;
  background: none;
  font-size: ${({theme}) => theme.font.S};
  opacity: 1;
  color: ${({theme}) => theme.primaryColor};
  font-weight: 700;
  margin: 0;
  width: 100%;
  text-align:center;
  margin-top: 10px;
  cursor: pointer;
  svg{
    margin-right: 10px;
  }
`;

const AditionalFinanceTypes = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 5px;
  >div{
    padding: 0px 0px;
    h3{
       font-size: ${({theme}) => theme.font.S};
    opacity: 1;
    color: ${({theme}) => theme.thirdColor};
     font-weight: 500;
     margin: 0;
    }
  }
  // >div:first-of-type{
  //   border-right: 1px solid ${({theme}) => theme.secondColor};
  // }
`;

const Flex = styled.div`
 display: flex;
 flex-wrap: wrap;
 align-items: center;
 >div{
  margin-right: 10px;
 }
`;

const Grid = styled.div`
  display:grid;
  grid-template-columns: ${({gtc}) => gtc || '1fr 1fr 1fr'};
  grid-gap:10px;
  //margin-top: 10px;
`;

const Block = styled.div`
  margin-bottom: 20px;
`;

const ContentBody = styled.div`
  background: white;
  flex: 1 1;
  min-height: 100%;
  padding: 15px;
  border-radius: 5px;
  margin-top: 15px;
`;

export const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;


const Wrapper = styled.div`

`;
